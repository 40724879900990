import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'rxjs';
import { Observable } from 'rxjs';
import { TokenService } from '../../../../_services/tokenService/tokenservice.service';
import { AuthenticationService } from '../../../../_services/auth.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { EncryptDecryptService } from '../../../../Common/EncryptDecrypt/encrypt-decrypt';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  errorMessage = '';
  loginForm: FormGroup;
  isSubmitted = false;
  hasError: boolean = false;
  isLoading: boolean = false;

  @Output() loggedIn = new EventEmitter<any>();
  @Output() logInFailure= new EventEmitter<any>();
  constructor(
    private router: Router,
    private formBuilder: FormBuilder, private http: HttpClient,
    private token: TokenService,
    private toastr: ToastrManager,
    private authService: AuthenticationService,
    private encryptDecryptService: EncryptDecryptService
  ) {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
    
  }
  ngOnInit(): void {
    //if (this.token.getToken()) {
    //  this.router.navigate(['/tacs/fields']);
    //} else {
    //  this.router.navigate(['/login']);
    //}
  }

  initForm() {
    this.loginForm = this.formBuilder.group({
      email: [this.loginForm.value.email,[Validators.required]
      ],
      password: [this.loginForm.value.password,[Validators.required]
      ],
    });
  }

  get formControls(): any {
    return this.loginForm.controls;
  }

  onLogin(): void {
    this.isSubmitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.isLoading = true;
    this.authService.login(this.loginForm.value).subscribe(result => {
      if (result.isSuccess == true) {       
        this.router.navigateByUrl('/tacs/fields');
      } else {
        this.toastr.warningToastr(result.message, 'Not Found !');
      }
      this.isLoading = false;
    }, error => {
      this.toastr.errorToastr(error, 'Some ERROR occured!');
      this.isLoading = false;
    });
  }
}
