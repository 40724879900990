import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { ConfirmPasswordValidator } from './confirm-password.validator';
import { first } from 'rxjs/operators';
import { UserModel } from '../../_models/user.model';
import { AuthenticationService } from '../../../../_services/auth.service';
import { UserRegistration } from '../../_models/registration.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit, OnDestroy {
  success: boolean;
  error: string;
  userRegistration: UserRegistration = { username: '', email: '', password: '', name: '', roleid:0, isactive : false };
  submitted: boolean = false;

  registrationForm: FormGroup;
  hasError: boolean;
  isLoading$: Observable<boolean>;
  @Output() loggedIn = new EventEmitter<any>();
  @Output() logInFailure = new EventEmitter<any>();

  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  constructor(
    private fb: FormBuilder,
    private authenticationService: AuthenticationService,
    private router: Router,
    private http: HttpClient,
    private toastr: ToastrManager,
  ) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.registrationForm = this.fb.group(
      {
        username: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(100),
          ]),
        ],
        email: [
          '',
          Validators.compose([
            Validators.required,
            Validators.email,
            Validators.minLength(3),
            Validators.maxLength(320), // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
          ]),
        ],
        password: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(3),
            Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{6,}$"),
            Validators.maxLength(100),
          ]),
        ],
        cPassword: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(3),
            Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{6,}$"),
            Validators.maxLength(100),
          ]),
        ],
        agree: [false, Validators.compose([Validators.required])],
      },
      {
        validator: ConfirmPasswordValidator.MatchPassword,
      }
    );
  }
 
  get f() {
    return this.registrationForm.controls;
  }
  
  onSubmit() {
    if (this.registrationForm.invalid) {
      return;
    }
    this.hasError = false;
    
    this.userRegistration.username = this.registrationForm.value.username;
    this.userRegistration.email = this.registrationForm.value.email;
    this.userRegistration.password = this.registrationForm.value.password;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };    
    this.http.post('api/auth/register', this.userRegistration)
      .subscribe(
        (data: any) => {
          if (data.isSuccess) {
            this.toastr.successToastr(data.message, 'Success!');
            this.router.navigate(['/login']);
          } else {
            this.toastr.errorToastr(data.message, 'Error!');
            this.hasError = true;
          }
        },
        (error: any) => {
          this.error = 'API is unreachable. Please check your internet connection.';
          this.logInFailure.emit(true);
        }
      );
    //this.unsubscribe.push(registrationSubscr);
  }


  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
