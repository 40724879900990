import { NgModule } from '@angular/core';
import { ExtraOptions, Routes, RouterModule } from '@angular/router';
import { FieldlistingComponent } from './Components/tacs/fields/fieldlisting/fieldlisting.component';
import { AuthComponent } from './modules/authentication/auth.component';
import { AuthGuardService } from './_services/authguard/authguard.service';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  }, 
  {
    path: '',
    canActivateChild: [AuthGuardService],
    loadChildren: () =>
      import('./Components/spage.module').then((m) => m.SpageModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./modules/authentication/authentication.module').then((m) => m.AuthenticationModule)
   
  },
  {
   path: '',
   loadChildren: () =>
     import('./modules/errors/errors.module').then((m) => m.ErrorsModule),
  }
  //{
  //  path: '**',
  //  component: FieldlistingComponent
  //}
];

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  onSameUrlNavigation: 'reload',
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
