import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './_components/login/login.component';
import { AuthComponent } from './auth.component';
import { TranslationModule } from '../i18n';
import { AuthenticationRoutes } from './auth-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ng6-toastr-notifications';
import { RouterModule } from '@angular/router';
import { RegistrationComponent } from './_components/registration/registration.component';


@NgModule({
  declarations: [
    LoginComponent,
    RegistrationComponent,
    AuthComponent,
  ],
  imports: [
    CommonModule,
    TranslationModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    RouterModule.forChild(AuthenticationRoutes)
  ]
})
export class AuthenticationModule { }
