import { Injectable } from '@angular/core';
import { AuthenticationService } from '../auth.service';


@Injectable()
export class TokenService {
  constructor(
    private authenticationService: AuthenticationService
  ) { }
  getToken(): string {
    //return window.sessionStorage['token'];
    let currentUser = this.authenticationService.currentUserValue;
    return currentUser.token;
  }  

  //saveToken(token: string) {
  //  window.sessionStorage['token'] = token;
  //}

  destroyToken() {
    window.localStorage.removeItem('currentUser');
    window.sessionStorage.removeItem('currentUser');
    //localStorage.clear();
   // sessionStorage.clear();
  } 
}
