<!--<div class="row justify-content-center">
  <div class="col-md-4 text-center">
    <form *ngIf="!success" #f="ngForm" (ngSubmit)="onSubmit()">
      <img class="mb-4" src="assets/images/angular_solidBlack.svg" alt="" width="125" height="125">
      <h1 class="h3 mb-3 font-weight-normal">Register</h1>
      <div class="form-group">
        <label for="username" class="sr-only">Name</label>
        <input type="text" id="name" name="username" [(ngModel)]="userRegistration.username" class="form-control" placeholder="Your name" required autoFocus>
      </div>
      <div class="form-group">
        <label for="email" class="sr-only">Email address</label>
        <input type="email" id="email" name="email" #email="ngModel" [(ngModel)]="userRegistration.email" class="form-control" placeholder="Email address" email required>
        <small [hidden]="email.valid || (email.pristine && !submitted)" class="text-danger">Please enter a valid email</small>
      </div>
      <div class="form-group">
        <label for="password" class="sr-only">Password</label>
        <input type="password" id="password" name="password" [(ngModel)]="userRegistration.password" class="form-control" placeholder="Password" aria-describedby="passwordHelp" required>
        <small id="passwordHelp" class="form-text text-muted">Min. 6 characters with at least one non alphanumeric character</small>
      </div>
      <button class="btn btn-lg btn-primary btn-block" type="submit" [disabled]="!f.form.valid">Go</button>

      <div *ngIf="error" class="alert alert-danger mt-3" role="alert">
        <strong>Oops!</strong> {{error}}
      </div>
    </form>
    <div *ngIf="success" class="alert alert-success" role="alert">
      <h4 class="alert-heading">Well done!</h4>
      <p>Your account was created successfully, <a routerLink="/login">Please login</a> to continue.</p>
    </div>
  </div>
</div>-->


<form class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      novalidate="novalidate"
      id="kt_login_signup_form"
      [formGroup]="registrationForm"
      (ngSubmit)="onSubmit()">
  <!--begin::Heading-->
  <div class="mb-10 text-center">
    <h1 class="text-dark mb-3">Create an Account</h1>
    <div class="text-gray-400 fw-bold fs-4">
      Already have an account?
      <a routerLink="/login"
         class="link-primary fw-bolder"
         [style.margin-left]="'5px'">Sign in?</a>
    </div>
  </div>
  <!--end::Heading-->

  <!--<button type="button" class="btn btn-light-primary fw-bolder w-100 mb-10">
    <img alt="Logo"
         src="./assets/media/svg/brand-logos/google-icon.svg"
         class="h-20px me-3" />Sign in with Google
  </button>

  <div class="d-flex align-items-center mb-10">
    <div class="border-bottom border-gray-300 mw-50 w-100"></div>
    <span class="fw-bold text-gray-400 fs-7 mx-2">OR</span>
    <div class="border-bottom border-gray-300 mw-50 w-100"></div>
  </div>-->

  <!-- begin::Alert error-->
  <ng-container *ngIf="hasError">
    <div class="mb-lg-15 alert alert-danger">
      <div class="alert-text font-weight-bold">
        The registration details are incorrect
      </div>
    </div>
  </ng-container>
  <!-- end::Alert error-->
  <!--begin::Form group-->
  <div class="row fv-row mb-7">
    <label class="form-label fw-bolder text-dark fs-6">Fullname</label>
    <input class="form-control form-control-lg form-control-solid"
           type="text"
           name="username"
           formControlName="username"
           placeholder="Name"
           autocomplete="off"
           [ngClass]="{
        'is-invalid': registrationForm.controls['username'].invalid,
        'is-valid': registrationForm.controls['username'].valid
      }" />
    <ng-container [ngTemplateOutlet]="formError"
                  [ngTemplateOutletContext]="{
        validation: 'required',
        message: 'Name is required',
        control: registrationForm.controls['username']
      }"></ng-container>
    <ng-container [ngTemplateOutlet]="formError"
                  [ngTemplateOutletContext]="{
        validation: 'minlength',
        message: 'Name should have at least 3 symbols',
        control: registrationForm.controls['username']
      }"></ng-container>
    <ng-container [ngTemplateOutlet]="formError"
                  [ngTemplateOutletContext]="{
        validation: 'maxLength',
        message: 'Name should have maximum 100 symbols',
        control: registrationForm.controls['username']
      }"></ng-container>
  </div>
  <!--end::Form group-->
  <!--begin::Form group-->
  <div class="fv-row mb-7">
    <label class="form-label fw-bolder text-dark fs-6">Email</label>
    <input class="form-control form-control-lg form-control-solid"
           type="email"
           placeholder="Email"
           name="email"
           formControlName="email"
           autocomplete="off"
           [ngClass]="{
        'is-invalid': registrationForm.controls['email'].invalid,
        'is-valid': registrationForm.controls['email'].valid
      }" />
    <ng-container [ngTemplateOutlet]="formError"
                  [ngTemplateOutletContext]="{
        validation: 'required',
        message: 'Email is required',
        control: registrationForm.controls['email']
      }"></ng-container>
    <ng-container [ngTemplateOutlet]="formError"
                  [ngTemplateOutletContext]="{
        validation: 'email',
        message: 'Email is invalid',
        control: registrationForm.controls['email']
      }"></ng-container>
    <ng-container [ngTemplateOutlet]="formError"
                  [ngTemplateOutletContext]="{
        validation: 'minlength',
        message: 'Email should have at least 3 symbols',
        control: registrationForm.controls['email']
      }"></ng-container>
    <ng-container [ngTemplateOutlet]="formError"
                  [ngTemplateOutletContext]="{
        validation: 'maxLength',
        message: 'Email should have maximum 360 symbols',
        control: registrationForm.controls['email']
      }"></ng-container>
  </div>
  <!--end::Form group-->
  <!--begin::Form group-->
  <div class="mb-10 fv-row">
    <label class="form-label fw-bolder text-dark fs-6">Password</label>
    <input class="form-control form-control-lg form-control-solid"
           type="password"
           placeholder="Password"
           name="password"
           formControlName="password"
           autocomplete="off"
           [ngClass]="{
        'is-invalid': registrationForm.controls['password'].invalid,
        'is-valid': registrationForm.controls['password'].valid
      }" />
    <ng-container [ngTemplateOutlet]="formError"
                  [ngTemplateOutletContext]="{
        validation: 'required',
        message: 'Password is required',
        control: registrationForm.controls['password']
      }"></ng-container>
    <ng-container [ngTemplateOutlet]="formError"
                  [ngTemplateOutletContext]="{
        validation: 'minlength',
        message: 'Password should have at least 3 symbols',
        control: registrationForm.controls['password']
      }"></ng-container>
    <ng-container [ngTemplateOutlet]="formError"
                  [ngTemplateOutletContext]="{
        validation: 'maxLength',
        message: 'Password should have maximum 100 symbols',
        control: registrationForm.controls['password']
      }"></ng-container>
  </div>
  <!--end::Form group-->
  <!--begin::Form group-->
  <div class="fv-row mb-5">
    <label class="form-label fw-bolder text-dark fs-6">Confirm Password</label>
    <input class="form-control form-control-lg form-control-solid"
           type="password"
           placeholder="Confirm password"
           name="cPassword"
           autocomplete="off"
           formControlName="cPassword"
           [ngClass]="{
        'is-invalid': registrationForm.controls['cPassword'].invalid,
        'is-valid': registrationForm.controls['cPassword'].valid
      }" />
    <ng-container [ngTemplateOutlet]="formError"
                  [ngTemplateOutletContext]="{
        validation: 'required',
        message: 'Confirm Password is required',
        control: registrationForm.controls['cPassword']
      }"></ng-container>
    <ng-container [ngTemplateOutlet]="formError"
                  [ngTemplateOutletContext]="{
        validation: 'minlength',
        message: 'Confirm Password should have at least 3 symbols',
        control: registrationForm.controls['cPassword']
      }"></ng-container>
    <ng-container [ngTemplateOutlet]="formError"
                  [ngTemplateOutletContext]="{
        validation: 'maxLength',
        message: 'Confirm Password should have maximum 100 symbols',
        control: registrationForm.controls['cPassword']
      }"></ng-container>
    <ng-container *ngIf="
        registrationForm.controls['cPassword'].errors &&
        registrationForm.controls['cPassword'].errors.ConfirmPassword
      ">
      <div class="fv-plugins-message-container">
        <div class="fv-help-block">
          'Passsword' and 'Confirm Password' didn't match.
        </div>
      </div>
    </ng-container>
  </div>
  <!--end::Form group-->
  <!--begin::Form group-->
  <!--<div class="fv-row mb-10">
    <div class="form-check form-check-custom form-check-solid">
      <input id="kt_login_toc_agree"
             type="checkbox"
             formControlName="agree"
             name="agree"
             class="form-check-input" />

      <label for="kt_login_toc_agree"
             class="form-check-label fw-bold text-gray-700 fs-6">
        I Agree the&nbsp;<a href="https://keenthemes.com/metronic/?page=faq"
                            target="_blank"
                            class="ms-1 link-primary">terms and conditions</a>.
      </label>
    </div>
  </div>-->
  <!--end::Form group-->
  <!--begin::Form group-->
  <div class="text-center">
    <button type="submit" id="kt_sign_up_submit"            
            class="btn btn-lg btn-primary w-100 mb-5">
      <ng-container>
        <span class="indicator-label">Submit</span>
      </ng-container>      
    </button>
    <a routerLink="/login"
       type="button"
       id="kt_login_signup_form_cancel_button"
       class="btn btn-lg btn-light-primary w-100 mb-5">
      Cancel
    </a>
  </div>
  <!--end::Form group-->
</form>

<ng-template #formError  let-control="control"  let-message="message" let-validation="validation">
  <ng-container *ngIf="control.hasError(validation) && (control.dirty || control.touched)">
    <div class="fv-plugins-message-container">
      <div class="fv-help-block">
        <span role="alert">
          {{ message }}
        </span>
      </div>
    </div>
  </ng-container>
</ng-template>

