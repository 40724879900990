import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ng6-toastr-notifications';
import { AuthenticationModule } from './modules/authentication/authentication.module';
import { TokenService } from './_services/tokenService/tokenservice.service';
import { TokenExpiryService } from './_services/tokenService/tokenExpiry.service';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AuthenticationService } from './_services/auth.service';
import { ErrorInterceptor } from './_helper/error.interceptor';
import { JwtInterceptor } from './_helper/jwt.interceptor';
import { AuthGuardService } from './_services/authguard/authguard.service';
import { AuthgService } from './_services/authguard/auth.service';





@NgModule({
  declarations: [
    AppComponent,            
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    ToastrModule.forRoot(),
    HttpClientModule,
    ClipboardModule,
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    AuthenticationModule,
  ], providers: [    
    TokenService,
    TokenExpiryService,
    AuthGuardService,
    AuthgService,
    //AuthenticationService,
    ErrorInterceptor,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
