import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TokenService } from './tokenService/tokenservice.service';
import { EncryptDecryptService } from '../Common/EncryptDecrypt/encrypt-decrypt';
export class User {
  userName: string;
  email: string;
  token: string;
  role: string;
  modules: any;
}
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public apiUrl = '';
  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string,
    private router: Router, private tokenService: TokenService, private encryptDecryptService: EncryptDecryptService)
  {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(this.encryptDecryptService.decryptData(sessionStorage.getItem('currentUser'))));
    this.currentUser = this.currentUserSubject.asObservable();
    this.apiUrl = baseUrl + 'api/auth/';
  }

  public get currentUserValue(): User {    
   return this.currentUserSubject.value;
  }

  setUser(user: User) {
    sessionStorage.setItem('currentUser', JSON.stringify(user));
    this.currentUserSubject.next(user); 
  }

  
  login(userDetails) {
    return this.http.post<any>(this.apiUrl + 'login', userDetails)
      .pipe(map(result => {
        if (result.isSuccess) {
          sessionStorage.setItem('currentUser', this.encryptDecryptService.encryptData(JSON.stringify(result.data)));
          localStorage.setItem('language', 'en-au');
          //localStorage.setItem('menu', '1');
          var module = result.data.modules[0];
          localStorage.setItem('modulecode', module?.code);
          this.currentUserSubject.next(result.data);
        }
        return result;
      }));
  }  

  logout() {
    return this.http.get<any>(this.apiUrl + 'logout')
      .pipe(map(result => {
        if (result.isSuccess) {
          sessionStorage.removeItem('currentUser');
          //localStorage.removeItem('menu');
          localStorage.removeItem('modulecode');
          sessionStorage.clear();
          localStorage.clear();
          this.currentUserSubject.next(null);
        }
        return result;
      }));
  }

  public hasEditPermission(moduleCode: string): boolean {

    let user = this.currentUserSubject.value;
    var isAdmin = user.role.includes("Admin");
    if (isAdmin)
      return isAdmin;

    var isEditPermission = user.modules?.find(x => x.code == moduleCode)?.userRolePermissions?.find(x => x.name == "Editor") != null;
    return isEditPermission;
  }

  //logout() {    
  //  sessionStorage.removeItem('currentUser');
  //  this.tokenService.destroyToken();
  //  localStorage.removeItem('menu');
  //  this.router.navigate(['/login']);
  //  this.currentUserSubject.next(null);
  //}

  ////setUserDetails() {
  ////  if (localStorage.getItem('authToken')) {
  ////    const userDetails = new User();
  ////    const decodeUserDetails = JSON.parse(window.atob(localStorage.getItem('authToken').split('.')[1]));
  ////    userDetails.userName = decodeUserDetails.sub;
  ////    userDetails.password = decodeUserDetails.firstName;
  ////    this.userData.next(userDetails);
  ////  }
  ////}
}
