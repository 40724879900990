import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthenticationService, User } from '../_services/auth.service';
import { tap } from 'rxjs/operators';
import { TokenService } from '../_services/tokenService/tokenservice.service';
import { Router } from '@angular/router';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private currentUserSubject: BehaviorSubject<User>;
  constructor(private authenticationService: AuthenticationService, private router: Router,) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    let currentUser = this.authenticationService.currentUserValue;   
    if (currentUser && currentUser.token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.token}`,
        }
      });
      request = request.clone({
        setHeaders: {
          "X-TimeZoneOffSet": this.getTimezoneOffset(),
        }
      });
    }
    //return next.handle(request)
    return next.handle(request).pipe(tap(() => { },
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {            
            this.authenticationService.logout().subscribe(result => {
              if (result.isSuccess == true) {
                this.router.navigate(['/login']);
              }
            });
            return;
          } else if (err.status === 403)
            this.router.navigate(['/tacs/fields']);
        } else {
          console.log('err.status :', err.status)
        }
      }));

  }
  private getTimezoneOffset(): string {
    return (String(new Date().getTimezoneOffset()));
  }
}

