import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './auth.component';
import { LoginComponent } from './_components/login/login.component';
import { RegistrationComponent } from './_components/registration/registration.component';


export const AuthenticationRoutes: Routes = [
  
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent
      },
      //{
      //  path: 'register',
      //  component: RegistrationComponent
      //},

      //{
      //  path: 'forgot',
      //  component: ForgotComponent
      //},
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      }
    ]
  }
];


