import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TokenService } from '../tokenService/tokenservice.service';

@Injectable()
export class AuthgService{

	constructor(
		private tokenService: TokenService,
	){}

	public isAuthenticate(): boolean{

		return (!!this.tokenService.getToken());
	}
	public isToken(): boolean{
		return (!!this.tokenService.getToken());
	}
}
