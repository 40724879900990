import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from '../../_services/tokenService/tokenservice.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit, OnDestroy {
  today: Date = new Date();

  constructor(private tokenService: TokenService, private router: Router) { }
  ngOnInit() {
    document.body.classList.add('bg-white');
    
  }


  ngOnDestroy() {
    document.body.classList.remove('bg-white');
  }
}
