import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import * as CryptoJS from 'crypto-js';

@Injectable({ providedIn: "root" })
export class EncryptDecryptService {
  encryptSecretKey = '035CD262-F9CF-4186-9C01-1DDB6D2B29D0';
  encryptData(data) {
    try {
      if (data != null) { return CryptoJS.AES.encrypt(JSON.stringify(data), this.encryptSecretKey).toString(); }
    } catch (e) {
      console.log(e);
    }
  }

  decryptData(data) {
    try {
      if (data != null) {const bytes = CryptoJS.AES.decrypt(data, this.encryptSecretKey);
        if (bytes.toString()) {
          return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        }
      }
      return data;
    } catch (e) {
      console.log(e);
    }
  }
}
