<form class="form w-100" [formGroup]="loginForm" novalidate="novalidate" (ngSubmit)="onLogin()">
  <div class="text-center mb-10">
    <!--<div class="text-gray-400 fw-bold fs-4">
      New Here?
      <a  routerLink=""
         id="kt_login_signup"
         class="link-primary fw-bolder">{{ "AUTH.GENERAL.SIGNUP_BUTTON" | translate }}</a>
    </div>-->
  </div>

  <!--begin::Form Username-->
  <div class="fv-row mb-10">   
    <label class="form-label text-dark fw-bolder fs-6">
      Email / UserName<span class="text-danger">*</span>
    </label>


    <input class="form-control form-control-lg form-control-solid" type="email"
           name="username" placeholder="Email / UserName" formControlName="email" autocomplete="off" />
    <div class="validationError" *ngIf="isSubmitted && formControls.email.errors">
      <div class="text-danger" *ngIf="formControls.email.errors.required">Email/UserName is required</div>
    </div>
  </div>


  <!--begin::Password-->
  <div class="fv-row mb-10">          
    <label class="form-label text-dark fw-bolder fs-6">
      Password<span class="text-danger">*</span>
    </label>
    <input class="form-control form-control-lg form-control-solid"
           type="password"placeholder="Password" name="password"  autocomplete="off"
           formControlName="password" />
       <div class="validationError" *ngIf="isSubmitted && formControls.password.errors">
        <div  class="text-danger" *ngIf="formControls.password.errors.required">Password is required</div>
      </div>
  </div>
 

  <div class="text-center">
    <button type="submit" id="kt_sign_in_submit" class="btn btn-lg btn-primary w-100 mb-5" [disabled]="isLoading">
      <!--<ng-container>
        <span class="indicator-label">Continue</span>
      </ng-container>-->
      <ng-container *ngIf="isLoading">
        <span class="indicator-progress" [style.display]="'block'">
          Please wait...
          <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span>
      </ng-container>
      <ng-container *ngIf="!isLoading">
        <span class="indicator-label">Continue</span>
      </ng-container>
    </button>
  </div>
</form>

